import { graphql } from 'gatsby'
import React, { FC, useEffect } from 'react'
import Layout from '@/containers/Layout'
import PageContainer from '@/containers/PageContainer'
import { SanityPage } from 'web/graphql-types'

interface PageProps {
  data: {
    page: SanityPage
  }
}

const Page: FC<PageProps> = ({ data: { page: sanityPage } }) => {
  return(
  <Layout meta={sanityPage.meta}>
    <PageContainer page={sanityPage} />
  </Layout>
)}

export default Page

export const query = graphql`
  query($id: String!) {
    page: sanityPage(id: { eq: $id }) {
      title
      meta {
        ...metaData
      }
      backButton
      route {
        ... on SanityPage {
          _type
          slug {
            current
          }
        }
        ... on SanityPost {
          _type
          slug {
            current
          }
        }
        ... on SanityStore {
          _type
          slug {
            current
          }
        }
      }
      contentModules {
        ...contentModulesData
      }
    }
  }
`
