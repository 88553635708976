import React, { FC, useEffect, useContext } from 'react'
import { NavigationContext } from '@/contexts/NavigationContext'
import { SanityPage } from 'web/graphql-types'
import ModuleLoop from '@/containers/ModulesLoop'
import { getUri } from '@/utils/routing'

interface PageProps {
  page: SanityPage
}
const PageContainer: FC<PageProps> = ({
  page: { title, meta, backButton, route, contentModules }
}) => {
  const { setRoute, setBackButton } = useContext(NavigationContext)
  useEffect(() => {
    setBackButton(backButton || false)
    setRoute(backButton ? getUri(route?.slug?.current, route?._type) : null)
  }, [])

  return <ModuleLoop modules={contentModules?.modules} />
}

export default PageContainer
